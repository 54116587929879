import { saveToLocalStorage } from "@/composables/helperUtils";
import { Commit } from "vuex";
import {
  KcmRealtalkState,
  KcmContent,
  KcmAsset,
  RealtalkSettings,
  VirtualBackgroundTypes,
  CustomImageBackdropTypes,
} from "@/types";

export const fontSizeLimits = {
  min: 8,
  max: 48,
  step: 1,
};

const avgWordsPerMinute = 200;
export const scrollSpeedLimits = {
  min: avgWordsPerMinute * 0.2,
  max: avgWordsPerMinute * 3,
  step: avgWordsPerMinute * 0.2,
  multiplier: 0.1,
};

export const teleprompterTransparencyLimits = {
  min: 0,
  max: 100,
  step: 10,
  default: 60,
};

export const countdownTimerOptions = [
  { value: 3, text: "3 secs" },
  { value: 4, text: "4 secs" },
  { value: 5, text: "5 secs" },
  { value: 6, text: "6 secs" },
  { value: 7, text: "7 secs" },
  { value: 8, text: "8 secs" },
  { value: 9, text: "9 secs" },
  { value: 10, text: "10 secs" },
];

export const defaultSettings = {
  fontSize: 16,
  scrollSpeed: avgWordsPerMinute,
  teleprompterTransparency: 40,
  boldFont: false,
  autoStop: false,
  mirrorCamera: false,
  countdownTimer: 3,
  virtualBackground: "",
  virtualBackgroundType: VirtualBackgroundTypes.None,
  customImageBackdropType: CustomImageBackdropTypes.Color,
  customImageBackdropColor: "#000000",
};

// Can be deleted once CustomImageBackdropTypes
// is expanded to included "blur".
// 2.12.25
const getSafetyOverrides = (): Partial<RealtalkSettings> => ({
  customImageBackdropType: CustomImageBackdropTypes.Color,
});

// Can be deleted once CustomImageBackdropTypes
// is expanded to included "blur".
// 2.12.25
const overrideUnsafeValues = (
  settings: RealtalkSettings
): RealtalkSettings => ({
  ...settings,
  ...getSafetyOverrides(),
});

const getDefaultState = (): KcmRealtalkState => {
  const scriptFromStorage = localStorage.getItem("realtalk.script");
  let script: KcmContent | null = null;
  if (scriptFromStorage) {
    script = JSON.parse(scriptFromStorage);
  }

  const tempTitleFromStorage = localStorage.getItem("realtalk.tempTitle");
  let tempTitle = "";
  if (tempTitleFromStorage) {
    tempTitle = JSON.parse(tempTitleFromStorage);
  }

  const tempSavedRecordingFromStorage = localStorage.getItem(
    "realtalk.savedRecording"
  );
  let savedRecording: KcmAsset | null = null;
  if (tempSavedRecordingFromStorage) {
    savedRecording = JSON.parse(tempSavedRecordingFromStorage);
  }

  const peripheralIdsFromStorage = localStorage.getItem("realtalk.peripherals");

  let peripherals = {
    cameraId: null,
    microphoneId: null,
  };

  if (peripheralIdsFromStorage) {
    peripherals = JSON.parse(peripheralIdsFromStorage);
  }

  let settings = defaultSettings;

  const profiles = localStorage.getItem("profile.profile");
  if (profiles) {
    const parsed = JSON.parse(profiles);

    // if settings value is empty use default
    if (!parsed.realtalk?.realtalk_settings?.value) {
      settings = defaultSettings;
    } else {
      const parsedSettings: RealtalkSettings = JSON.parse(
        parsed.realtalk.realtalk_settings.value
      );

      // extra precaution to ensure parsed settings do not include
      // any old values from settings that are temporarily disabled
      settings = overrideUnsafeValues(parsedSettings);
    }
  }

  const takesFromStorage = localStorage.getItem("realtalk.takes");

  let takes = 0;
  if (takesFromStorage) {
    takes = JSON.parse(takesFromStorage);
  }

  return {
    script,
    tempTitle,
    savedRecording,
    settings,
    takes,
    peripherals,
  };
};

export const state: KcmRealtalkState = getDefaultState();

export const getters = {
  getScript(state: KcmRealtalkState): KcmContent | null {
    return state.script;
  },
  getTempTitle(state: KcmRealtalkState): string {
    return state.tempTitle;
  },
  getSavedRecording(state: KcmRealtalkState): KcmAsset | null {
    return state.savedRecording;
  },
  getFontSize(state: KcmRealtalkState): number {
    return state.settings.fontSize;
  },
  getScrollSpeed(state: KcmRealtalkState): number {
    return state.settings.scrollSpeed;
  },
  getTeleprompterTransparency(state: KcmRealtalkState): number {
    return (
      state.settings.teleprompterTransparency ??
      teleprompterTransparencyLimits.default
    );
  },
  shouldBoldFont(state: KcmRealtalkState): boolean {
    return state.settings.boldFont;
  },
  shouldAutoStop(state: KcmRealtalkState): boolean {
    return state.settings.autoStop;
  },
  shouldMirrorCamera(state: KcmRealtalkState): boolean {
    return state.settings.mirrorCamera;
  },
  getCountdownTimer(state: KcmRealtalkState): number {
    return state.settings.countdownTimer;
  },
  getSettings(state: KcmRealtalkState): RealtalkSettings {
    return state.settings;
  },
  isMinFontSize(state: KcmRealtalkState): boolean {
    return state.settings.fontSize === fontSizeLimits.min;
  },
  isMaxFontSize(state: KcmRealtalkState): boolean {
    return state.settings.fontSize === fontSizeLimits.max;
  },
  isMinScrollSpeed(state: KcmRealtalkState): boolean {
    return state.settings.scrollSpeed === scrollSpeedLimits.min;
  },
  isMaxScrollSpeed(state: KcmRealtalkState): boolean {
    return state.settings.scrollSpeed === scrollSpeedLimits.max;
  },
  getTakes(state: KcmRealtalkState): number {
    return state.takes;
  },
  getSavedCameraId(state: KcmRealtalkState): string | null {
    return state.peripherals.cameraId;
  },
  getSavedMicrophoneId(state: KcmRealtalkState): string | null {
    return state.peripherals.microphoneId;
  },
  getCustomImageBackdropType(state: KcmRealtalkState): string | null {
    return state.settings.customImageBackdropType;
  },
  getCustomImageBackdropColor(state: KcmRealtalkState): string | null {
    return state.settings.customImageBackdropColor;
  },
  getVirtualBackgroundSource(state: KcmRealtalkState): string {
    return state.settings.virtualBackground + `?${Date.now()}`;
  },
  getVirtualBackgroundType(state: KcmRealtalkState): VirtualBackgroundTypes {
    return state.settings.virtualBackgroundType;
  },
  hasVirtualBackground(state: KcmRealtalkState): boolean {
    return state.settings.virtualBackground !== "";
  },
  useVirtualBackground(state: KcmRealtalkState): boolean {
    return (
      state.settings.virtualBackgroundType === VirtualBackgroundTypes.Image ||
      state.settings.virtualBackgroundType === VirtualBackgroundTypes.Blur
    );
  },
};

export const actions = {
  setScript({ commit }: { commit: Commit }, script: KcmContent | null): void {
    commit("SET_SCRIPT", script);
    commit("SET_TAKES", 0);
  },
  setTempTitle({ commit }: { commit: Commit }, tempTitle: string): void {
    commit("SET_TEMP_TITLE", tempTitle);
  },
  setSavedRecording(
    { commit }: { commit: Commit },
    savedRecording: KcmAsset | null
  ): void {
    commit("SET_SAVED_RECORDING", savedRecording);
  },
  setMirrorCamera({ commit }: { commit: Commit }, mirrorCamera: boolean): void {
    commit("SET_MIRROR_CAMERA", mirrorCamera);
  },
  setFontSize({ commit }: { commit: Commit }, size: number): void {
    commit("SET_FONT_SIZE", size);
  },
  setScrollSpeed({ commit }: { commit: Commit }, speed: number): void {
    commit("SET_SCROLL_SPEED", speed);
  },
  setTeleprompterTransparency(
    { commit }: { commit: Commit },
    transparency: number
  ): void {
    commit("SET_TELEPROMPTER_TRANSPARENCY", transparency);
  },
  setSettings(
    { commit }: { commit: Commit },
    settings: RealtalkSettings
  ): void {
    commit("SET_SETTINGS", settings);
  },
  setTakes({ commit }: { commit: Commit }, takes: number): void {
    commit("SET_TAKES", takes);
  },
  setCameraId({ commit }: { commit: Commit }, cameraId: string): void {
    commit("SET_CAMERA_ID", cameraId);
  },
  setMicrophoneId({ commit }: { commit: Commit }, microphoneId: string): void {
    commit("SET_MICROPHONE_ID", microphoneId);
  },
  incrementTakes({
    commit,
    state,
  }: {
    commit: Commit;
    state: KcmRealtalkState;
  }): void {
    let updatedTake = state.takes;
    commit("SET_TAKES", (updatedTake += 1));
  },
  setVirtualBackgroundType(
    { commit }: { commit: Commit },
    type: VirtualBackgroundTypes
  ): void {
    commit("SET_TYPE", type);
  },
};

export const mutations = {
  SET_SCRIPT(state: KcmRealtalkState, newValue: KcmContent | null): void {
    state.script = newValue;
    saveToLocalStorage("realtalk.script", state.script);
  },
  SET_TEMP_TITLE(state: KcmRealtalkState, newValue: string): void {
    state.tempTitle = newValue;
    saveToLocalStorage("realtalk.tempTitle", state.tempTitle);
  },
  SET_SAVED_RECORDING(
    state: KcmRealtalkState,
    newValue: KcmAsset | null
  ): void {
    state.savedRecording = newValue;
    saveToLocalStorage("realtalk.savedRecording", state.savedRecording);
  },
  SET_MIRROR_CAMERA(state: KcmRealtalkState, newValue: boolean): void {
    state.settings.mirrorCamera = newValue;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
  SET_FONT_SIZE(state: KcmRealtalkState, newValue: number): void {
    state.settings.fontSize = newValue;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
  SET_SCROLL_SPEED(state: KcmRealtalkState, newValue: number): void {
    state.settings.scrollSpeed = newValue;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
  SET_TELEPROMPTER_TRANSPARENCY(
    state: KcmRealtalkState,
    newValue: number
  ): void {
    state.settings.teleprompterTransparency = newValue;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
  SET_SETTINGS(
    state: KcmRealtalkState,
    updatedSettings: RealtalkSettings
  ): void {
    state.settings = updatedSettings;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
  SET_TAKES(state: KcmRealtalkState, takes: number): void {
    state.takes = takes;
    saveToLocalStorage("realtalk.takes", state.takes);
  },
  SET_CAMERA_ID(state: KcmRealtalkState, newValue: string): void {
    state.peripherals.cameraId = newValue;
    saveToLocalStorage("realtalk.peripherals", state.peripherals);
  },
  SET_MICROPHONE_ID(state: KcmRealtalkState, newValue: string): void {
    state.peripherals.microphoneId = newValue;
    saveToLocalStorage("realtalk.peripherals", state.peripherals);
  },
  SET_TYPE(state: KcmRealtalkState, newType: VirtualBackgroundTypes): void {
    state.settings.virtualBackgroundType = newType;
    saveToLocalStorage("realtalk.settings", state.settings);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
