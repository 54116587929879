import {
  RecurlyAccountBalanceData,
  RecurlyAccountData,
  RecurlyCouponData,
  RecurlyPlanData,
  RecurlySubscriptionData,
  RecurlyError,
  RecurlyPurchaseData,
} from "./recurly.types";
import {
  KCMPPFieldMinimal,
  KCMPPFieldConverted,
  KCMPPProfiles,
  KCMPPMemberFlag,
  KCMPPValuesTableData,
  KCM_PP_FE_BooleanField,
} from "./kcm-profile-api.types";
import { KcmFeatureProfile } from "kcm-vue-components/dist/composables/oldGraphicsService/types";

export type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

export type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export interface KcmContentArrayResult {
  content?: KcmContent[];
  total_count?: number;
  has_more?: boolean;
  error?: string;
}

export interface KcmContentArrayResponse {
  data?: {
    content?: KcmContent[];
    search_results?: KcmContent[];
  };
  error?: string;
}

export interface KcmAssetArrayResult {
  assets?: KcmAsset[];
  total_count?: number;
  has_more?: boolean;
  error?: string;
}

export interface KcmAssetResponse {
  data: {
    asset: KcmAsset;
  };
}

export interface KcmAssetArrayResponse {
  data?: {
    assets?: KcmAsset[];
  };
  error?: string;
}

export interface KcmContent {
  id: string;
  can_share?: string;
  contents: string;
  content_type: string;
  slug: string;
  featured_image: string;
  title: string;
  description: string;
  download?: string;
  excerpt?: string;
  gif?: string;
  tags?: string[];
  hashtags?: string;
  full_text?: string;
  feed_files?: string;
  story_files?: string;
  feed_hashtags?: string;
  story_hashtags?: string;
  categories?: KcmCategory[];
  webinar_time?: string;
  is_video?: string;
  video_thumbnail?: string;
  api_v1_id?: string;
  related?: KcmContent[] | null;
  sources?: string;
  translations: {
    [key: string]: KcmTranslation;
  };
  assets?: KcmAsset[];
  kcm_ig_caption?: string;
  kcm_ig_hashtags?: string;
  public_bottom_line?: string;
  agents_bottom_line?: string;
  created_at?: string;
  published_at: string;
  status?: string;
  expired_at?: string | null;
  updated_at: string;
  original_id?: number;
  user_id?: number;
  crm_list_id?: string;
  live_webinar_url?: string;
  link?: string;
  link_text?: string;
  page_pattern?: string;
  variant?: string;
  dismissible?: string;
  poll?: string;
  modified?: boolean;
  based_on?: number;
}

export interface KcmContentShare extends KcmContent {
  aspect_ratio: string;
  length: number;
  customization: "custom" | "kcm" | "modified";
}

export type KcmContentCreate = Omit<
  KcmContent,
  "id" | "created_at" | "updated_at" | "categories" | "assets" | "translations"
> & {
  translations?: KcmTranslation[];
  categories?: string[];
  assets?: number[];
};

export type KcmContentUpdate = Omit<
  KcmContent,
  | "id"
  | "content_type"
  | "created_at"
  | "updated_at"
  | "categories"
  | "assets"
  | "translations"
> & {
  translations?: KcmTranslation[];
  categories?: string[];
  assets?: number[];
};

export interface KcmContentCards extends KcmContentArrayResult {
  content?: KcmContentCard[];
}

export interface KcmContentCard extends KcmContent {
  route?: string;
  complete?: boolean;
  path?: string;
  button_copy?: string;
}

export interface KcmTranslation {
  contents: string;
  featured_image: string;
  title: string;
  description: string;
  excerpt?: string;
  gif?: string;
  hashtags?: string;
  feed_files?: string;
  story_files?: string;
  feed_hashtags?: string;
  story_hashtags?: string;
  categories?: KcmCategory[];
  is_video?: string;
  video_thumbnail?: string;
  api_v1_id?: string;
  related?: KcmContent[];
  sources?: string;
  assets?: KcmAsset[];
  kcm_ig_caption?: string;
  kcm_ig_hashtags?: string;
  public_bottom_line?: string;
  agents_bottom_line?: string;
  language?: string;
}

export interface KcmAsset {
  id: number;
  title: string;
  description: string | null;
  url: string;
  asset_type: string;
  categories: string[];
  tags: string[];
  language: string;
  updated_at: string;
  user_id: number;
  related_content: KcmContent[];
  metadata?: AssetMetadata;
}

type AssetMetadata = {
  duration?: string;
  thumbnail?: string;
  thumbnail_timestamp?: string;
  aspect_ratio?: string;
};

type AssetType =
  | "image"
  | "pdf"
  | "document"
  | "slides"
  | "audio"
  | "video"
  | "link"
  | "zip"
  | "other";

export interface CreateAsset {
  title: string;
  description?: string;
  url: string;
  asset_type: AssetType;
  categories: string[];
  tags: string[];
  content_ids: number[];
  user_id?: number;
  metadata: AssetMetadata;
}

export interface UpdateAsset {
  title?: string;
  description?: string;
  url?: string;
  categories?: string[];
  tags?: string[];
  metadata?: AssetMetadata;
}

export interface KcmAssetGroup {
  [key: string]: KcmAsset[];
}

export interface AssetLinks {
  copy: string;
  link: string;
}

export interface SlidesData {
  slides: string[];
  slug: string;
  title: string;
}

export interface KcmContentResult {
  content?: KcmContent;
  error?: string;
}

export interface KcmContentResponse {
  data?: {
    content?: KcmContent;
  };
  error?: string;
}

export interface KcmCategory {
  created_at: string;
  id: number;
  name: string;
  published_at: string;
  slug: string;
  status: string;
  updated_at: string;
}

export interface KcmCategoryResponse {
  data?: {
    categories?: KcmCategory[];
  };
  error?: string;
}

export interface KcmCategoryResult {
  categories?: KcmCategory[];
  error?: string;
}

export interface KcmProfilePostData {
  position: string;
  value: string;
  sync_to: string;
  font_size: number;
  values?: KCMPPValuesTableData[];
  table?: string;
  field_type?: string;
}

export interface KcmProfileFieldGraphicsEditor extends KCMPPFieldMinimal {
  value: string;
  table?: string;
  values?: Record<string, string>[];
}

export interface KcmCustomLocalMarket {
  market_ids: string;
  market_name: string;
  market_zips: string;
}

export interface KcmFeatureProfileGraphicsEditor {
  [key: string]: KcmProfileFieldGraphicsEditor;
}

export interface KcmProfileState {
  profile: KCMPPProfiles;
  flags: KCMPPMemberFlag[];
  last_sync: number;
}

export interface KcmRealtalkState {
  script: KcmContent | null;
  tempTitle: string;
  savedRecording: KcmAsset | null;
  settings: RealtalkSettings;
  takes: number;
  peripherals: RealtalkPeripherals;
}

export interface RealtalkPeripherals {
  cameraId: string | null;
  microphoneId: string | null;
}

export enum VirtualBackgroundTypes {
  None = "none",
  Blur = "blur",
  Image = "image",
}

export enum CustomImageBackdropTypes {
  Blur = "blur",
  Color = "color",
}

export interface RealtalkSettings {
  fontSize: number;
  scrollSpeed: number;
  teleprompterTransparency: number;
  boldFont: boolean;
  autoStop: boolean;
  mirrorCamera: boolean;
  countdownTimer: number;
  virtualBackgroundType: VirtualBackgroundTypes;
  virtualBackground: string;
  customImageBackdropType: CustomImageBackdropTypes;
  customImageBackdropColor: string;
}

export interface KcmNetworkOptions {
  id?: string;
  schedule_time?: string;
  link?: string;
  file_url?: string;
  url?: string;
  source?: string | Blob;
  title?: string;
  name?: string;
  message?: string;
  description?: string;
  include_pp?: boolean;
  thumb?: string | null | Blob;
  attached_media?: { media_fbid: string }[];
  published?: string;
  linkedin_type?: string;
  facebook_page_post_id?: string;
  video_length?: number;
  aspect_ratio?: string;
  instagram_share_type?: IGShareType;
}

export type IGShareType = "REELS" | "STORIES";

export interface FacebookApiShareOptions {
  access_token: string;
  scheduled_publish_time?: string;
  published?: string;
  schedule_time?: string;
  link?: string;
  file_url?: string;
  url?: string;
  source?: string | Blob;
  title?: string;
  name?: string;
  message?: string;
  description?: string;
  thumb?: string | null | Blob;
  attached_media?: string;
}

export interface FacebookScheduledPost {
  success?: boolean;
  error?: string;
  id?: string;
  message?: string;
  permalink_url?: string;
  picture?: string;
  full_picture?: string;
  scheduled_publish_time?: number;
  attachments?: FacebookScheduledPostAttachmentData;
}

export interface FacebookScheduledPostAttachmentData {
  data: FacebookScheduledPostAttachment[];
}

export interface FacebookScheduledPostAttachment {
  description: string;
  media_type: string;
  title: string;
  unshimmed_url: string;
  url: string;
}

export interface SettingsStore {
  language: string;
  contentLanguage: string;
  window: {
    height: number;
    width: number;
  };
  adBlockEnabled: boolean;
  siteConfig: SiteConfig | undefined;
  configUpdated: number;
}

export interface SiteConfig {
  [key: string]: SiteConfigField;
}

export interface SiteConfigField {
  copy: string;
  data: {
    [key: string]: { copy: string; input: string; value: number | string };
  };
}

export type BillingSource = "guest" | "recurly" | "apple";
export interface BillingStore {
  source: BillingSource;
  subscription:
    | RecurlySubscriptionData
    | ApplePaySubscriptionData
    | BillingGenericNoData;
  account: RecurlyAccountData | BillingGenericNoData;
  plans: PlanData[];
  last_sync: number;
  redirect: string;
}

export interface KcmBilling {
  serviceId(): string;
  status(userId: string): Promise<string> | string;
  subscription_data?(
    userId?: string
  ):
    | Promise<RecurlySubscriptionData>
    | ApplePaySubscriptionData
    | BillingGenericNoData;
  account_data?(
    userId?: string
  ): Promise<RecurlyAccountData> | BillingGenericNoData;
  plans?(): Promise<RecurlyPlanData[] | BillingGenericNoData[]>;
  coupon_codes?(): Promise<RecurlyCouponData[] | BillingGenericNoData[]>;
  create_subscription?(
    id?: string,
    payload?: KCMSubscriptionCreateData
  ): Promise<RecurlySubscriptionData | BillingGenericError> | null;
  update_subscription?(
    id?: string,
    payload?: KCMSubscriptionUpdateData
  ): Promise<RecurlySubscriptionData | BillingGenericError> | null;
  create_change_subscription?(
    id?: string,
    payload?: KCMSubscriptionCreateChangeData
  ): Promise<RecurlySubscriptionData | BillingGenericError>;
  pause_subscription?(
    id?: string,
    length?: number,
    string?: string
  ): Promise<RecurlySubscriptionData | BillingGenericError> | null;
  resume_subscription?(
    id?: string
  ): Promise<RecurlySubscriptionData | BillingGenericError> | null;
  cancel_pending_changes?(
    subscriptionId?: string
  ): Promise<null | BillingGenericError> | null;
  cancel_subscription?(id?: string, payload?: string): Promise<null> | null;
  reactivate_subscription?(
    id?: string
  ): Promise<RecurlySubscriptionData | BillingGenericError> | null;
  update_account?(
    id?: string,
    payload?: string
  ): Promise<null | BillingGenericError> | null;
  validate_coupon?(
    code: string
  ): Promise<RecurlyCouponData | BillingGenericNoData> | BillingGenericNoData;
  apply_coupon?(id?: string, payload?: string): Promise<null> | null;
  account_balance?(id?: string): Promise<RecurlyAccountBalanceData> | null;
  redirects(): { [x: string]: string };
  parse_coupons?(couponData: RecurlyCouponData[] | unknown[]): KcmCouponData[];
  create_purchase?(
    payload?: RecurlyPurchaseData
  ): null | Promise<void | BillingGenericError>;
  get_prorated_charge?(
    user_id: string,
    plan: string,
    coupon: string
  ): Promise<number>;
  get_invoices?(
    user_id: string,
    params?: {
      limit?: number;
      ids?: number[];
      order?: string;
      sort?: string;
      begin_time?: string;
      end_time?: string;
      type?: string;
    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ): Promise<Record<string, any>[]>;
  download_invoice?(user_id: string, invoice_number: number): Promise<Blob>;
}

export interface KCMSubscriptionUpdateData {
  old: string;
  new: string;
  coupon_codes?: string[];
  timeframe?: string;
  price?: number;
}

export interface KCMSubscriptionCreateChangeData {
  timeframe?: string;
  add_ons?: KCMSubscriptionAddOns[];
}

export interface KCMSubscriptionAddOns {
  code: string;
  quantity: number;
}

export interface KCMSubscriptionCreateData {
  plan_code: string;
  coupon_codes?: string[];
  trial_ends_at?: string;
}

export interface KcmProfileSettingInterface extends Vue {
  updateProfile(): Promise<void>;
}

export interface KcmStoredContent {
  [slug: string]: {
    [language: string]: KcmContent;
  };
}

export interface KcmStoredCategories {
  [language: string]: KcmCategory[];
}

export interface KcmContentTypesText {
  slug: string;
  text: string;
}

export interface ContentStore {
  content: KcmStoredContent;
  categories: KcmStoredCategories;
}

export interface UserState {
  user: AuthUser;
  token: string | null;
  last_sync: number;
  forceRedirect: string | null;
  admin: AuthState | null;
  teamOwner: AuthState | null;
}

export interface AuthState {
  user: AuthUser;
  token: string;
}

export interface AuthUser {
  id?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  phone?: string;
  is_admin?: boolean;
  access_levels?: AuthAccessLevel[];
  features?: AuthUserFeature[];
  expired_features?: AuthUserFeature[];
  services?: AuthUserService[];
  status?: string;
  team?: AuthUserTeam;
  type?: string;
  parents?: {
    parent: AuthUser;
  }[];
  children?: {
    child: AuthUser;
  }[];
  last_login_date?: number;
}

export interface AuthUserTeam {
  created_at: string;
  id: number;
  name: string;
  owner_id: string;
  updated_at: string;
}

export interface AuthUserPost {
  id?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  phone?: string;
  features?: number[];
  status?: string;
  industry_id?: number;
  password?: string;
  access_level?: number;
  parent?: number;
  redirect?: string;
  team?: string;
}

export interface AuthApiResp {
  data: {
    user: AuthUser;
    token: string;
    multiple?: boolean;
    users?: AuthUser[];
  };
}

export interface AuthAccessLevel {
  access_level: number;
  industry: number;
}

export interface AuthUserFeature {
  id: string;
  slug: string;
  name: string;
  includes: AuthUserFeature[];
  is_plan: boolean;
  expires_at: string;
}

export interface AuthUserService {
  service: AuthService;
  expires?: string;
  scope?: string;
}

export interface AuthService {
  slug: string;
}

export interface GraphicsCanvasOptions {
  customizeEnabled: boolean;
  logoImage: HTMLImageElement | null;
  profileImage: HTMLImageElement | null;
  ehLogo: HTMLImageElement | null;
  realtorLogo: HTMLImageElement | null;
  logoColor: string;
  whitespace: boolean;
  hueshift: number;
  src: HTMLImageElement | HTMLVideoElement | null;
  srcUrl: string;
  showPersonalizationBar: boolean;
  backgroundColor: string;
  showLogo: boolean;
  addShadow: boolean;
  logoSize: number;
  logoTransparency: number;
  logoX: number;
  logoY: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface GraphicsCanvasInputOptions {
  buildSingleCanvas?: boolean;
  srcUrl?: string;
  backgroundColor?: string;
  brandColor?: string;
  clearMouse?: boolean;
  basicProfile?: KcmFeatureProfile;
  graphicsProfile?: KcmFeatureProfile;
  logoX?: number;
  logoY?: number;
  customizeEnabled?: boolean;
  whitespace?: boolean;
  hueshift?: number;
  showLogo?: boolean;
  addShadow?: boolean;
  logoSize?: number;
  logoTransparency?: number;
  showPersonalizationBar?: boolean;
  src?: HTMLImageElement | HTMLVideoElement | null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface FileGroup {
  contents: string;
  html: string;
  hashtags?: string;
  excerpt?: string;
  content_type: string;
  id: string;
  slug: string;
  published_at: string;
  updated_at: string;
  featured_image: string;
  title: string;
  description: string;
  translations: {
    [key: string]: KcmTranslation;
  };
  tags?: string[];
  categories?: KcmCategory[];
  poll?: string;
}

export interface GraphicFileGroups {
  feed: FileGroup | null;
  story: FileGroup | null;
}

export interface ProfileCardData {
  id: string;
  content_type: string;
  button_copy: string;
  route: string;
  featured_image: string;
  complete: boolean;
}

export interface PwdFields {
  current_password: string;
  password: string;
  confirmPassword: string;
}

export interface PersonalizationOptions {
  text: string;
  value: string;
}

export interface SelectOptions {
  text: string;
  value: string | null;
  disabled?: boolean;
}

export interface ContentTypeOptions {
  text: string;
  value: string;
  available: boolean;
}

export interface GuidesInfo {
  [key: string]: GuideInfo;
}

export interface GuideInfo {
  label: string;
  default: string;
  slug: string;
}

export interface EditorOptions {
  height: number;
  plugins: string[];
  toolbar: string;
  statusbar: boolean;
  menubar: boolean;
  content_style: string;
}

export interface EmailBuilderHeaderOptions {
  title: KCMPPFieldConverted;
  subtitle: KCMPPFieldConverted;
  color: string;
  scheme: string;
  show_logo: boolean;
  logo_size: number;
  show_subtitle: boolean;
}

export interface EmailBuilderFooterOptions {
  text_fields: KCMPPFieldConverted[];
  show_media: {
    [social_media: string]: string;
  };
  color: string;
  language: string;
  show_realtor: boolean;
  show_eho: boolean;
  show_ehl: boolean;
  show_logo: boolean;
  logo_size: number;
  show_guides: { buyer: boolean; seller: boolean } | null;
  show_footer: boolean;
  logo: string;
  avatar: string;
  guide_data: {
    buyers: KcmContent | null;
    sellers: KcmContent | null;
  };
}

export interface EmailBuilderSidebarOptions {
  text_fields: KCMPPFieldConverted[];
  show_media: {
    [social_media: string]: string;
  };
  show_logo: boolean;
  logo_size: number;
  color: string;
  language: string;
  show_guides: { buyer: boolean; seller: boolean } | null;
  logo: string;
  avatar: string;
  email: string;
  website: string;
  guide_data: {
    buyers: KcmContent | null;
    sellers: KcmContent | null;
  };
}

export interface ShareButtonsMoreOptionsData {
  shareInSpanish: boolean;
  view: string;
  shortUrl: boolean;
  embed: boolean;
}

export interface KcmServiceData {
  title: string;
  slug: string;
  icon: string;
  canSchedule?: boolean;
  connectMsg?: string;
  disconnectMsg?: string;
  canExpire?: boolean;
  disabledContentTypes: { [x: string]: string };
  upsell?: { [x: string]: string };
  login: boolean;
}

export interface GenericApiError {
  error: string;
}
export interface KcmAutoposterJobsResponse {
  data: {
    has_more: boolean;
    jobs: KcmAutoposterJob[];
    total_count: number;
  };
  error?: string;
}

export interface KcmAutoposterJobResponse {
  data?: {
    job: KcmAutoposterJob;
  };
  error?: string;
}

export interface KcmAutoposterJob {
  id: number;
  updated_at: string;
  created_at: string;
  user_id: number;
  descr: string;
  prefix: string;
  suffix: string;
  title: boolean;
  excerpt: boolean;
  hashtags: number;
  randomize: boolean;
  deleted_at: string | null;
  blog_layouts: KcmAutoposterBlogLayout[];
  post_types: KcmAutoposterPostType[];
  job_schedules: KcmAutoposterJobSchedule[];
  skip_categories: KcmAutoposterSkipCategories[];
  run: KcmAutoposterRun[];
  job_networks: KcmAutoposterNetworkData[];
  timezone: KcmAutoposterTimezone;
  industry_id: number;
  language: KcmAutoposterLanguage;
  status: boolean;
}

export interface KcmAutoposterJobPostData {
  user_id: number;
  descr: string;
  prefix: string;
  suffix: string;
  title: boolean;
  excerpt: boolean;
  hashtags: number;
  poll: boolean;
  randomize: boolean;
  blog_layouts: KcmAutoposterBlogLayout[];
  post_types: KcmAutoposterPostType[];
  job_schedules: KcmAutoposterJobSchedule[];
  skip_categories: KcmAutoposterSkipCategories[];
  job_networks: KcmAutoposterNetworkData[];
  timezone?: KcmAutoposterTimezone;
  timezone_id?: number;
  language: KcmAutoposterLanguage;
  status: boolean;
}

export interface KcmAutoposterBlogLayout {
  id: number;
  descr: string;
}

export interface KcmAutoposterPostType {
  id: number;
  slug: string;
  label: string;
}

export interface KcmAutoposterJobSchedule {
  hour: number;
  minute: number;
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export interface KcmAutoposterSkipCategories {
  category_id: number;
}

export interface KcmAutoposterRun {
  id: number;
  updated_at: string;
  created_at: string;
  job_id: number;
  post_data: string;
  run_steps: KcmAutoposterRunStep[];
  attempt: number;
  scheduled_for: string | null;
  completed_at: string | null;
  status_id: number;
  status: KcmAutoposterStatus;
}

export interface KcmAutoposterRunStep {
  id: number;
  run_id: number;
  network_data: string;
  response: string;
  started_at: string;
  updated_at: string;
  completed_at: string;
  status_id: number;
  status: KcmAutoposterStatus;
}
export interface KcmAutoposterStatus {
  id: number;
  slug: string;
  label: string;
}

export interface KcmAutoposterNetworkData {
  network_data: string;
  network: {
    id: number;
    slug: string;
    label: string;
  };
}

export interface KcmAutoposterTimezone {
  id: number;
  timezone: string;
  copy: string;
}

export interface KcmAutoposterLanguage {
  id: number;
  slug: string;
  label: string;
}

export interface RecurlySubscriptionResponse {
  subscription: RecurlySubscriptionData;
}

export interface BillingGenericError {
  error: string | RecurlyError;
}

export interface BillingGenericNoData {
  error: string;
}

export interface PlanData extends RecurlyPlanData {
  slug: string;
  price: number;
  legacy: number;
  video: string;
}

export interface KcmCouponData {
  id: string;
  code: string;
  name: string;
  state: string;
  type: string;
  value: string;
  duration: string;
  plans?: PlanData[] | "all";
}

export interface ApplePaySubscriptionData {
  original_transaction_id: string;
  status: string;
}

export interface DashboardNotificationData {
  id: string;
  show: boolean;
  variant: string;
  html: string;
}

export interface EmailBuilderFilterData {
  year: number;
  month: number;
  categories: string[];
  contentTypes: string[];
}

export interface EmailBuilderContentData extends KcmContent {
  contentSlot: number;
  selected: boolean;
}

export interface EmailBuilderSettingsData {
  show_header: KCM_PP_FE_BooleanField;
  show_footer: KCM_PP_FE_BooleanField;
  show_sidebar: KCM_PP_FE_BooleanField;
  show_subtitle: KCM_PP_FE_BooleanField;
  color_background: KCM_PP_FE_BooleanField;
  realtorlogo: KCM_PP_FE_BooleanField;
  ehlogo: KCM_PP_FE_BooleanField;
  eh_lenderlogo: KCM_PP_FE_BooleanField;
  sellerad: KCM_PP_FE_BooleanField;
  buyerad: KCM_PP_FE_BooleanField;
  pplocation: KCMPPFieldConverted;
  logo_pos: KCMPPFieldConverted;
  language: KCMPPFieldConverted;
  content_format: KCMPPFieldConverted;
  guides_location: KCMPPFieldConverted;
  subtitletext: KCMPPFieldConverted;
}

export interface IgCanvasGraphicBuilder {
  draw(
    ctx: CanvasRenderingContext2D,
    profileColor: string,
    quote: string,
    featuredImage: HTMLImageElement,
    logo: HTMLImageElement | null
  ): void;
}

export interface StatesJson {
  value: string | null;
  text: string;
}

export interface ZendeskSsoData {
  first_name: string;
  last_name: string;
  email: string;
  user_id: string;
  return_to?: string;
}

export interface CheckToggleData<T extends string> {
  copy: string;
  value: T;
  mobile?: string;
}

export enum ScriptToggleValues {
  SCRIPTS = "scripts",
  CUSTOM_SCRIPTS = "custom_scripts",
  RECORDINGS = "recordings",
}

export interface NavigationBorderTab {
  copy: string;
  mobile: string;
  route: string;
}

export interface SocialLoginPost {
  password: string;
  authMethod: string;
  industry_id: number;
  username?: string;
}

export const defaultBuilderSettings: EmailBuilderSettingsData = {
  show_header: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  show_footer: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  show_sidebar: {
    value: false,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  show_subtitle: {
    value: false,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  color_background: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  realtorlogo: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  ehlogo: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  eh_lenderlogo: {
    value: false,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  sellerad: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  buyerad: {
    value: true,
    fontSize: 0,
    SyncTo: "custom",
    field_type: "string",
  },
  pplocation: {
    value: "1",
    fontSize: 0,
    SyncTo: "custom",
  },
  logo_pos: {
    value: "1",
    fontSize: 0,
    SyncTo: "custom",
  },
  language: {
    value: "en",
    fontSize: 0,
    SyncTo: "custom",
  },
  content_format: {
    value: "1",
    fontSize: 0,
    SyncTo: "custom",
  },
  guides_location: {
    value: "1",
    fontSize: 0,
    SyncTo: "custom",
  },
  subtitletext: {
    value: "",
    fontSize: 0,
    SyncTo: "custom",
  },
};

export interface ParseScriptOutput {
  type: string;
  data: TextData;
  editable?: boolean;
}

export interface TextData {
  originalText: string;
  text: string;
  url?: string;
  id?: string;
}

export interface CtaListsInterface {
  GettingStartedWebinar: number;
  BrunchAndLearn: number;
  DailyBlog: number;
  MemberWebinar: number;
  OpenHouseOptIn: number;
  OpenHouseAccess: number;
  LocalOptIn: number;
}

export interface LocalBlockAIPayload {
  title: string;
  subtitle: string;
  marketName: string;
  labels: string;
  localData: string;
  nationalData?: string;
  action: string;
  language?: string;
  [key: string]: string | undefined;
}

export enum KCMCalendarContentTypes {
  WebinarReplay = "webinar-replay",
  TrainingVideo = "training-video",
  BestPractice = "best-practice",
}

declare global {
  interface Window {
    enableKcmDebug: boolean;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    FB: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    AppleID: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    _hsq: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    _hsp: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    recurly: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    gtag: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    gapi: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    convertflow: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    fbAsyncInit: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    hsConversationsSettings: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    HubSpotCallsToActions: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    YT: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    onYouTubeIframeAPIReady: any;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    pendo: any;
  }
}
